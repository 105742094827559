<template>
  <div>
    <div style="border: none">
      <div class="container">
        <div class="title">新用户注册</div>
        <div class="line"></div>
        <el-form
          :model="registerForm"
          :rules="registerRules"
          class="demo-registerForm"
          ref="registerFormRef"
        >
          <el-form-item prop="phone">
            <img alt="" class="img_l" src="@/assets/img/reset/username.png"/>
            <el-input
              maxlength="11"
              placeholder="请输入手机号"
              v-model="registerForm.phone"

            ></el-input>
          </el-form-item>
          <!-- <el-form-item prop="email">
            <img class="img_l" src="@/assets/img/reset/username.png" alt="" />
            <el-input
              v-model="registerForm.email"
              placeholder="请输入邮箱"


            ></el-input>
          </el-form-item> -->
          <el-form-item prop="code">
            <img alt="" class="img_l" src="@/assets/img/reset/code.png"/>
            <el-input
              maxlength="20"
              placeholder="请输入短信验证码"
              v-model="registerForm.code"

            ></el-input>
            <!-- 短信验证码 -->
            <!-- <div class="phoneCode">
             <span class="code" v-show="show" @click="getCode"
               >获取验证码</span
             >
             <span v-show="!show" class="count">{{ count }} s</span>
           </div>
         </el-form-item>  -->
            <!-- <el-form-item prop="code">
              <img class="img_l" src="@/assets/img/reset/code.png" alt="" />
              <el-input
                v-model="registerForm.code"
                placeholder="请输入邮箱注册验证码"
                maxlength="6"

              ></el-input> -->
            <!-- 短信验证码 -->
            <div class="phoneCode">
              <span @click="getCode" class="code" v-show="showCode"
              >获取验证码</span
              >
              <span class="count" v-show="!showCode">{{ count }} s</span>
            </div>
          </el-form-item>
          <el-form-item prop="password">
            <img alt="" class="img_l" src="@/assets/img/reset/password.png"/>
            <el-input
              :autocomplete="isShowPwd ? 'new-password' : 'off'"
              maxlength="20"
              placeholder="设置密码：6-12位字符，包含字母或数字"
              v-model="registerForm.password"
            ></el-input>
            <!-- <img
              @click="changePwdStatus()"
              v-if="!isShowPwd"
              class="img_r"
              src="@/assets/img/reset/eye01.png"
              alt=""
            />
            <img
              @click="changePwdStatus()"
              v-if="isShowPwd"
              class="img_r"
              src="@/assets/img/reset/eye01.png"
              alt=""
            /> -->
          </el-form-item>
          <el-form-item prop="rq_password">
            <img alt="" class="img_l" src="@/assets/img/reset/password.png"/>
            <el-input
              maxlength="20"
              placeholder="请再次输入登录密码"
              v-model="registerForm.rq_password"

            ></el-input>
          </el-form-item>
          <el-form-item style="margin: 40px 0 0 0"></el-form-item>
          <el-form-item>
            <el-button @click="submitForm()"> 注册</el-button>
          </el-form-item>
          <div class="box">
            <div @click="toLogin()" class="fr">已有账号？马上登录</div>
          </div>
        </el-form>
      </div>
    </div>
  </div>
</template>

<script>
  /* eslint-disable */
  import {registerCode, setAccountInfo,} from "r/base/register";
  import {mobileCheck} from "@/common/utils.js";
  import {intervalTime} from "store/time";

  export default {
    name: "base-register",
    data() {
      const pwdCheck = async (rule, value, callback) => {
        if (value !== this.registerForm.password) {
          callback(new Error("两次输入的密码不一致"));
        } else {
          callback();
        }
      };

      return {
        inputType: "",
        // 手机验证短信
        showCode: true,
        count: "",
        timer: null,
        codeTime: "", //手机验证码倒计时时间
        isShowPwd: true, // 控制密码显示隐藏
        registerForm: {
          phone: "",
          // email: "",
          code: "",
          password: "",
          rq_password: "",
        },
        // 表单验证规则对象
        registerRules: {
          // 对手机号进行校验
          phone: [
            {required: true, message: "请输入手机号", trigger: "blur"},
            {
              validator: function (rule, value, callback) {
                if (mobileCheck(value) === false) {
                  callback(new Error("请输入正确的手机号"));
                } else {
                  callback();
                }
              },
              trigger: "blur",
            },
          ],
          // 对邮箱验证
          // email: [
          //   { required: true, message: "请输入邮箱", trigger: "blur" },
          //   {
          //     validator: validator.validateEmail,
          //     trigger: "blur",
          //   },
          // ],
          // 对验证码进行校验
          code: [
            {required: true, message: "请输入验证码", trigger: "blur"},
            {min: 6, max: 20, message: "请输入正确的验证码", trigger: "blur"},
          ],
          // 对密码进行校验
          password: [
            {
              required: true,
              message: "请输入密码",
              transform: (value) => value,
              trigger: "blur",
            },
            {
              type: "string",
              message: "请输入不包含空格的字符",
              trigger: "blur",
              transform(value) {
                if (value && value.indexOf(" ") === -1) {
                  return value;
                } else {
                  return false;
                }
              },
            },
            {
              trigger: "blur",
              validator: (rule, value, callback) => {
                var passwordreg = /^[a-zA-Z0-9]{6,12}$/;
                // /(?=.*\d)(?=.*[a-zA-Z]).{6,12}/;
                if (!passwordreg.test(value)) {
                  callback(new Error("请输入6-12位，包含数字/字母,"));
                } else {
                  callback();
                }
              },
            },
          ],
          // 对密码进行校验
          rq_password: [
            {required: true, message: "请输入确认密码", trigger: "blur"},
            {
              validator: pwdCheck,
              trigger: "blur",
            },
          ],
        },
      };
    },
    created() {
      const endTime = window.localStorage.getItem("register_code_time");
      const phone = window.localStorage.getItem("register_code_phone");
      if (endTime > 0) {
        this.registerForm.phone = phone;
        this.intervalHandleNew(endTime);
      }
      this.commonApi(39)
    },
    methods: {
      // 去注册
      toLogin() {
        this.$router.push({
          path: "/baseLogin",
          query: {
            ...this.$store.state.query,
          }
        });
      },
      // 改变密码显示状态
      changePwdStatus() {
        this.isShowPwd = !this.isShowPwd;
      },
      // 定时器倒计时
      intervalHandleNew(timeLag) {
        if (!this.timer) {
          this.count = timeLag - 1;
          this.showCode = false;
          this.timer = setInterval(() => {
            if (this.count > 0 && this.count <= timeLag) {
              this.count--;
              window.localStorage.setItem("register_code_time", this.count);
            } else {
              clearInterval(this.timer);
              this.showCode = true;
              this.timer = null;
            }
          }, 1000);
        }
      },
      // 定时器倒计时
      intervalHandle(startTime, endTime) {
        // 时间差
        const timeLag = intervalTime(startTime, endTime);
        if (!this.timer) {
          this.count = timeLag - 1;
          this.showCode = false;
          this.timer = setInterval(() => {
            if (this.count > 0 && this.count <= timeLag) {
              this.count--;
            } else {
              this.showCode = true;
              clearInterval(this.timer);
              this.timer = null;
            }
          }, 1000);
        }
      },
      // 获取手机验证短信
      getCode() {
        if (!this.registerForm.phone) {
          return this.$message.error("请输入手机号");
        }
        // 接口获得验证码
        registerCode({
          phone: this.registerForm.phone,
        }).then((res) => {
          console.log(res, "passwordCode");
          if (res.data.code != 200) {
            return this.$message.error(res.data.msg);
          }
          this.$message.success(res.data.msg);

          this.codeTime = res.data.expire_time;
          window.localStorage.setItem("register_code_time", res.data.expire_time);
          window.localStorage.setItem("register_code_phone", this.registerForm.phone);
          this.intervalHandleNew(res.data.expire_time);
        });
      },
      // 表单验证
      submitForm() {
        this.$refs["registerFormRef"].validate((valid) => {
          if (!valid) return;
          setAccountInfo({
            phone: this.registerForm.phone,
            code: this.registerForm.code,
            password: this.registerForm.password,
            rq_password: this.registerForm.rq_password,
          }).then((res) => {
            console.log(res);
            if (res.data.code != 200) {
              return this.$message.error(res.data.msg);
            }
            this.$message.success(res.data.msg);
            // token存储
            this.$store.commit('login', res.data.Token);
            window.localStorage.setItem("index-phone-all", this.registerForm.phone);

            let previousPath = this.$store.state.previousPath;
            this.$router.push({
              path: previousPath,
              query: {
                ...this.$store.state.query,
              }
            });
            this.$store.commit('setPreviousPath', '/');
          });
        });
      },
    },
    destroyed() {
      this.timer && clearInterval(this.timer);
      localStorage.removeItem('register_code_time');
      localStorage.removeItem('register_code_phone');
    }
  };
</script>

<style lang="scss" scoped>
  @media screen and (max-width: 1500px) {
    .container {
      padding: 30px 50px 20px 50px !important;

      .title {
        font-size: 20px !important;
      }

      ::v-deep .el-form {
        margin-top: 20px !important;

        .el-form-item {
          margin-bottom: 20px !important;

          .el-form-item__content {
            img.img_l {
              width: 24px !important;
            }

            .el-input {
              font-size: 12px !important;
            }

            .el-input__inner {
              height: 40px !important;
            }

            .el-button {
              font-size: 15px !important;
              margin: 0 !important;
              height: 42px !important;
              line-height: 50px !important;
            }
          }
        }
      }
    }
  }

  // 注册输入
  .container {
    background: #ffffff;
    box-shadow: 0px 1px 18px 0px rgba(0, 0, 0, 0.06);
    border-radius: 8px;
    padding: 40px 60px;
    margin-top: 0;
    overflow: hidden;

    .title {
      // margin: 19px 0 6px 0;
      // width: 132px;
      height: 20px;
      font-size: 22px;
      font-weight: 500;
      color: var(--all_color);
      line-height: 20px;
    }

    // .line {
    //   width: 103px;
    //   height: 3px;
    //   background: var(--all_color);
    //   border-radius: 2px;
    // }

    ::v-deep .el-form {
      margin-top: 60px;

      .el-form-item {
        position: relative;
        margin-bottom: 30px;

        &:last-child {
          margin: 0;
        }

        .el-form-item__content {
          position: relative;

          .phoneCode {
            position: absolute;
            z-index: 999;
            top: 50%;
            right: 25px;
            transform: translate(0, -50%);
            cursor: pointer;

            .code {
              font-size: 12px;
              font-weight: 600;
              color: var(--all_color);
            }
          }

          img.img_l {
            position: absolute;
            left: 25px;
            top: 50%;
            transform: translate(-50%, -50%);
            z-index: 1000;
          }

          img.img_r {
            position: absolute;
            right: 15px;
            top: 50%;
            transform: translate(0, -50%);
            z-index: 1000;
          }

          .el-input__inner {
            height: 50px;
            line-height: 50px;
            padding: 0 10px 0 50px;
            border-radius: 0;
            color: #4d4d4d;
            border: 0;
            border-bottom: 2px solid #d9d9d9;

            &:focus {
              border-bottom-color: var(--all_color);
            }
          }

          .el-form-item__error {
            color: #e0823d;
          }

          .el-button {
            width: 100%;
            height: 60px;
            background: var(--all_color);
            border-radius: 4px;
            opacity: 0.8;
            line-height: 50px;
            text-align: center;
            color: #fff;
            font-size: 16px;
            padding: 0;
            margin: 30px 0 0 0;
            border: none;
          }
        }
      }

      .box {
        font-size: 12px;
        height: 18px;
        display: block;

        .fr {
          // float: left;
          text-align: center;
          height: 18px;
          line-height: 18px;
          color: #999999;
          margin-top: -10px;
          cursor: pointer;

          &:hover {
            color: var(--all_color);
            text-decoration: underline;
          }
        }
      }
    }
  }

  @media screen and (max-width: 1601px) {
    .container {
      right: 18%;
    }
  }

  @media screen and (max-width: 1501px) {
    .container {
      right: 16%;
    }
  }

  @media screen and (max-width: 1401px) {
    .container {
      right: 14%;
    }
  }
</style>
